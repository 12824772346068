import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const state = {
  role: 0,
  fileUploadProgress: 0,
  token: '',
  userInfo: {
    department_name: "",
    role_type: 0,
    username: "",
  },
  taskForm: {}, // 督导form
  departmentList: [],
  listStatus: {
    loading: true,
    finished: false,
  }
};

const getters = {
  getRole: state => state.role,
  getFileUploadProgress: state => state.fileUploadProgress,
  getUserInfo: state => state.userInfo,
  getToken: state => state.token,
  getTaskForm: state => state.taskForm,
  getDepartmentList: state => state.departmentList,
};

const mutations = {
  mutation_role(state, role) {
    state.role = role;
    console.log('SET_ROLE', role);
  },
  mutation_fileUploadProgress(state, newVal) {
    state.fileUploadProgress = newVal;
  },
  mutation_userInfo(state, userInfo) {
    state.userInfo = userInfo;
  },
  mutation_token(state, token) {
    state.token = token;
  },
  mutation_taskForm(state, taskForm) {
    state.taskForm = taskForm;
  },
  mutation_departmentList(state, departmentList) {
    console.log('=======', departmentList);
    state.departmentList = departmentList;
  },
  mutation_listStatus(state, listStatus) {
    state.listStatus = listStatus;
  }
};


const actions = {
  // action_role({ commit }, newVal) {
  //   console.log('context---', newVal);
  //   commit('mutation_role', newVal);
  // },
  // action_fileUploadProgress({ commit }, newVal) {
  //   commit('mutation_fileUploadProgress', newVal);
  // },
  // action_userInfo({ commit }, newVal) {
  //   commit('mutation_userInfo', newVal);
  // },
  // action_token({ commit }, newVal) {
  //   commit('mutation_token', newVal);
  // }
};

export default new Vuex.Store({
  state,
  mutations,
  getters,
  actions,
})