import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from './store';
import Cookies from 'js-cookie';
import axios from 'axios';
import { Button, Form, Field, Tab, Tabs, Uploader, Tabbar, TabbarItem, CellGroup, Cell, Icon, Search, List, RadioGroup, Radio, Notify, Toast, Dialog, Calendar, Picker, Popup, ActionSheet } from 'vant';

Vue.use(Button);
Vue.use(Form);
Vue.use(Field);
Vue.use(Tab);
Vue.use(Tabs);
Vue.use(Uploader);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(CellGroup);
Vue.use(Cell);
Vue.use(Icon);
Vue.use(Search);
Vue.use(List);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Calendar);
Vue.use(Picker);
Vue.use(Popup);
Vue.use(ActionSheet);
Vue.config.productionTip = false

Vue.use(Cookies);
Vue.prototype.$axios = axios;
Vue.prototype.$Cookies = Cookies;
Vue.prototype.$Notify = Notify;
Vue.prototype.$Toast = Toast;
Vue.prototype.$Dialog = Dialog;
console.log('-------', store)
// Cookies.remove('token');
// Cookies.remove('role');
// Cookies.remove('user_info');

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
