<template>
  <div id="app">
    <router-view></router-view>
    <van-tabbar v-model="active" v-show="showTabbar">
      <van-tabbar-item class="tabbarItem" @click="toPage('/list/task')" icon="todo-list-o"><span class="tabBarName">督办</span></van-tabbar-item>
      <van-tabbar-item class="tabbarItem" @click="toPage('/list/notice')" icon="label-o"><span class="tabBarName">通知</span></van-tabbar-item>
      <van-tabbar-item class="tabbarItem" @click="toPage('/stat')" icon="points" v-if="role === 0"><span class="tabBarName">统计</span></van-tabbar-item>
      <van-tabbar-item class="tabbarItem" @click="toPage('/user')" icon="home-o"><span class="tabBarName">{{userInfo.department_name||''}}</span></van-tabbar-item>
    </van-tabbar>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      role: undefined,
      userInfo: {},
      active: 0,
      showTabbar: true,
      noTabbar: ['/role', '/login', '/task', '/taskReply', '/notice', '/detailTask', '/detailNotice', '/resetPassword', '/resetMobile', '/organization', '/massesSupervisor', '/massesDetail']
    }
  },
  computed: {
  },
  watch: {
    $route: {
      handler: function(newVal, oldVal){
        console.log(newVal, oldVal)
        this.role = this.$Cookies.get('role') ? +this.$Cookies.get('role') : undefined;
        this.userInfo = this.$Cookies.get('user_info')? JSON.parse(this.$Cookies.get('user_info')) : {};
        if (this.noTabbar.includes(newVal.path)) {
          this.showTabbar = false;
        } else {
          this.showTabbar = true;
        }
        if (oldVal.path === '/login' || newVal.query.fromStatPage === 'true') {
          this.active = 0;
        }
        if (newVal.path === '/login' && this.$Cookies.get('token')) {
          const loginStatus = this.$Cookies.get('token');
          console.log(loginStatus);
          this.$router.push({ path: '/list/task' })
        }
      },
      deep: true
    }
  },
  methods: {
    toPage(path) {
      this.$router.push({ path });
    }
  },
}
</script>

<style lang="less">
@import '~@/styles/reset.less';
#app {
  width: 100vw;
  height: 100vh;
  background-color: #f7f8fa;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  .tabbarItem {
    .van-tabbar-item__text {
      height: 14px;
    }
  }
  .tabBarName {
    display: inline-block;
    width: 25vw;
    height: 14px;
    line-height: 12px;
    box-sizing: border-box;
    padding: 1px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .van-uploader__preview-image, .van-uploader__upload {
    width: 70px !important;
    height: 70px !important;
  }
}
</style>
