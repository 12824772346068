import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router)


/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */
// 公共路由
const constantRoutes = [
  {
    path: '/',
    name: 'role',
    component: (resolve) => require(['@/views/role'], resolve),
  },
  {
    path: '/role',
    name: 'role',
    component: (resolve) => require(['@/views/role'], resolve),
  },
  {
    path: '/login',
    name: 'login',
    component: (resolve) => require(['@/views/login'], resolve),
  },
  {
    path: '/list/:type',
    name: 'list',
    component: (resolve) => require(['@/views/list'], resolve),
  },
  {
    path: '/task',
    name: 'task',
    component: (resolve) => require(['@/views/task'], resolve),
  },
  {
    path: '/taskReply',
    name: 'taskReply',
    component: (resolve) => require(['@/views/taskReply'], resolve),
  },
  {
    path: '/notice',
    name: 'notice',
    component: (resolve) => require(['@/views/notice'], resolve),
  },
  {
    path: '/detailTask',
    name: 'detailTask',
    component: (resolve) => require(['@/views/detailTask'], resolve),
  },
  {
    path: '/detailNotice',
    name: 'detailNotice',
    component: (resolve) => require(['@/views/detailNotice'], resolve),
  },
  {
    path: '/user',
    name: 'user',
    component: (resolve) => require(['@/views/user'], resolve),
  },
  {
    path: '/stat',
    name: 'stat',
    component: (resolve) => require(['@/views/stat'], resolve),
  },
  {
    path: '/organization',
    name: 'organization',
    component: (resolve) => require(['@/views/organization'], resolve),
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: (resolve) => require(['@/views/resetPassword'], resolve),
  },
  {
    path: '/resetMobile',
    name: 'resetMobile',
    component: (resolve) => require(['@/views/resetMobile'], resolve),
  },
  {
    path: '/massesSupervisor',
    name: 'massesSupervisor',
    component: (resolve) => require(['@/views/massesSupervisor'], resolve),
  },
  {
    path: '/massesDetail',
    name: 'massesDetail',
    component: (resolve) => require(['@/views/massesDetail'], resolve),
  },
]
const router = new Router({
  mode: 'history', // 去掉url中的#
  routes: constantRoutes
})
router.beforeEach((to, from, next) => {
  // console.log('router---', to, from);
  if (to.path === '/') {
    next({ path: '/role' });
  }
  next();
})


export default router;
